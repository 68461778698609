.iframe {
  height: 1rem;
  display: inline;
}

.iframe iframe {
  margin-top: 1px;
  max-width: 70%;
  max-height: 1.4rem;
  display: inline;
  overflow: visible;
  line-height: 1.4rem;
  font-size: 1rem;
}
